.topNavigator{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: 10px;
    margin-top: 50px;
    border-radius: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}
.pTag{
    font-weight: 700;
    font-size: 14px;
    margin-left: 14px;
    font-family: "Commissioner", sans-serif;
    font-optical-sizing: auto;
}



@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
html{
  font-family: "Nanum Gothic", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
.MuiButtonBase-root {
  color: rgb(92, 87, 87) !important;
}

.sidebarListItem a.link {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: rgb(83, 83, 83) !important;
}

.searchBar {
  color: #2f323b !important;
}
.model-selector {
  appearance: none;
  padding: 10px;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  width: 200px;
}

.model-selector:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.model-selector option {
  padding: 10px;
}
.MuiInputBase-root,
.MuiTablePagination-actions {
  margin-top: -15px !important;
}
/* DARK MODE */

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.sidebarTitle {
  color: #9a9a9a !important;
}
.sidebar {
  background-color: #2f323b !important;
  /* #be6464 */
}
.css-1k455el {
  background-color: #2f323b !important;
}

.css-1nnrorg-MuiPaper-root-MuiAppBar-root {
  background: linear-gradient(to right, #ffffff 0%, #ffcccc 100%) !important;
}
a.link.active .sidebarListItem {
  color: #2f323b !important;
}
.sidebarListItem:hover {
  color: #2f323b !important;
}

/* ALL FORM CSS  */
.userDetCont {
  display: flex;
  min-width: 560px;
  margin: 60px 10px 0px 10px;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubmitBtnBox {
  display: flex;
  align-items: center;
}

.detailTitle {
  font-size: 24px;
  font-weight: 600;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.updateDataBtn {
  margin: 10px auto 0px auto;
  min-width: 100px;
  background-color: #3bb077;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

.outerMost {
  padding: 20px;
}

.secondBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
}

.mainBoxx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.headingDiv {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.imGCont {
  padding: 10px;

  margin-top: 40px;
}

.myImg {
  object-fit: cover;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  min-width: 250px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.formContainer {
  flex: 3;
  padding: 40px;
}

.formInMain {
  display: grid;
  grid-template-columns: 1.6fr 3fr;
}

.myLabel {
  padding: 5px;
  margin: 10px;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
}

.myInput {
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dbb3b3;
}

.smallboxes {
  margin: 10px;
}

.PanelTopBar {
  background: #37BA9A !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;
    height: 60px !important;
}

body {
  background-color: #e9ecef !important;
}

.loader {
  margin-top: 300px;
}

a {
  text-decoration: none !important;
  font-size: 18px !important;
  color: black !important;
}
.text-Change{
  font-size: 25px;
  padding: 20px;
  margin-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.view {
  position: relative;
}
.view .fa-solid{
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.detailBarInput{
  border: none;
  font-size: 15px;
  margin-top: 8px;
  outline: none;
  padding: 10px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  width: 100% !important;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 9px !important;
}
.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 14px !important;
  font-weight: 300 !important;
  background-color: #10121b !important;
  color: white !important;
}
.rowBg{
  background-color: #e9ecef;
}
.cropButton-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 90vh;
}
.cropButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.reactEasyCrop_Container {
  top: 20% !important;
  left: 20% !important;
  right: 20% !important;
  bottom: 20% !important;
}
.categoryImage{
  width: 80px;
  height: 80px;
  border-radius: 80px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
.cropper{
  height: 400px;
}
.doneButtons {
  display: flex;
  gap: 40px;
  justify-content: flex-end;
  align-items: flex-end;
}




.tripiBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-size: 14px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 500;
  font-style: normal;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #37BA9A 0%, #2a9c80 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.tripiBtn :focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.newBtn2 {
  font-size: 13px;
  font-weight: 500;
  padding: 3px 13px;
  border: 2px solid #a88a70; /* Set a transparent border by default */
  color: #a88a70;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out, border 0.2s ease-in-out; /* Added transition property for smooth effect */
  cursor: pointer;
}

.newBtn2:hover {
  color: white;
  border: 2px solid transparent;
  background-color: #a88a70;
}

.errorMessage {
  font-size: 18px;
  font-weight: 400;
  color: #8a817c; /* or any other shade of grey you prefer */
  font-family: system-ui;
}

.fileErr {
  font-size: 14px;
  color: red;
  text-align: center;
}
.user-certificate {
  width: 300px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.certificate {
  position: relative;
}

.view-label {
  position: absolute;
  top: 120px;
  left: 120px;
  background-color: rgba(0, 0, 0, 0.5); /* Faded black background */
  color: white;
  padding: 5px 10px; /* Adjust padding as needed */
  cursor: pointer;
  opacity: 1; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.certificate:hover .view-label {
  opacity: 1; /* Show label on hover */
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard {
  margin-top: -31px !important;
}

.topBarIcon {
  color: #ffffff  !important;
  font-size: 30px !important;
}

.popUpCreate {
  background-color: #2b2d31;
  padding: 30px;
}
.popHeading {
  font-size: 22px;
  margin-top: 10px;
  color: white;
  font-family: ui-monospace;
  font-weight: 500;
}
.popText {
  color: lightslategrey;
  font-size: 15px;
  font-family: ui-monospace;
  margin-left: 7px;
  font-weight: 500;
  margin-top: 10px;
}

.popInput {
  width: 250px;
  background: black;
  padding: 11px;
  border-radius: 50px;
  outline: none;
  border: none;
  color: white;
}
.popInput:focus {
  outline: none;
  border: none;
}

.popBtn {
  background: white;
  color: black;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  padding: 8px 25px;
  font-family: ui-monospace !important;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.bookingIcon{
  font-size: 18px !important;
  color: #212529;
  margin-top: 3px;
}

.banner-provider{
  height: 100px;
  width: 100px;
}
.statusChipPending {
  background-color: #ac772e !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  border-radius: 20px;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipCompleted {
  background-color: #5cb85c !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipFailed {
  background-color: #d9534f !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.errText {
  font-size: 13px;
  color: red;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 8px;
}
.type{
  font-size: 13px;
  font-weight: 600;
  text-decoration-line: underline;
  color: #10121b;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.purchaseChip {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  gap: 4px;
  padding: 5px 0px 4px 8px;
  background: linear-gradient(to bottom right, #669999 0%, #333399 100%);
  font-size: 10px;
  font-weight: 500;
  color: white;
  border-radius: 30px;
}

.removedUser-Access {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.adminCreation {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #1886e0;
  padding: 8px 12px;
  border-radius: 20px;
  gap: 5px;
}

.hiddenTopic {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.newMessage {
  position: relative;
  display: inline-flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #00a331;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
  overflow: hidden;
}

.newMessage::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 20px;
  background-color: rgba(0, 163, 49, 0.5);
  opacity: 0;
  animation: glowing 2s ease-in-out infinite;
}

@keyframes glowing {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.featuredPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #006600 0%, #339933 100%);
  padding: 6px 10px;
  border-radius: 20px;
  gap: 5px;
}
.normalPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #0099cc 0%, #0066cc 100%);
  padding: 7px 10px;
  border-radius: 20px;
  gap: 5px;
}

.detailPageImage {
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}

.detailHeading{
  font-size: 16px;
  font-weight: 600;
  font-family: system-ui;
  color: #515050;
}

.userDate{
  color: rgb(136, 136, 136);
  font-size: 14px;
  font-weight: 600;
  font-family: system-ui;
}

.images{
  position: relative;
  top: -15px;
  right: 30px
}
 .images img{
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 30px;
}
.images img:nth-child(2){
  left: 15px;
}
.images img:nth-child(3){
  left: 30px;
}
 .images img:nth-child(4){
  left: 40px;
}
.group-detailsImage{
  width: 120px;
  height: 120px;
  border-radius: 100px;
}

.detials-card{
  border: 1px solid lightgray;
  padding: 10px;
  height: auto;
}


.tabs{
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: system-ui !important;
}


.group-posts{
  height: 100vh;
  overflow: auto;
}

.posts{
  display: flex;
  margin-top: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  gap: 10px;
  padding: 15px;
}

.posts .heading{
  font-size: 16px;
  font-weight: 600;
  font-family: system-ui;
  color: #10121b;
}

.posts .desc{
  font-size: 15px;
  font-weight: 400;
  font-family: system-ui;
  color: #353536;
}

.posts .content{
  font-size: 13px !important;
  color: #252525;
  font-family: system-ui;
}

.viewText{
  color: #444;
  font-family: system-ui;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
}

.userList {
  flex: 4;
  padding: 20px;
}

.mainBox {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

/* .topNavigator{
    background-color: rgb(219, 184, 184);
    margin-bottom: 40px;
    border-radius: 6px;
    color: #443d3d;
}
.pTag{
    padding: 6px;
    font-weight: 600;
    font-size: 14px;
} */

.userListUser {
  display: flex;
  align-items: center;
}

.email.true {
  color: #73b998 !important;
  /* color: white !important; */
}

.block {
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  margin-right: 20px;
  margin-left: 0;
}

.block:active {
  transform: translateY(2px);
}

.visibilityIc.active {
  transform: translateY(1px);
}

.block.true {
  color: #0f6e40 !important;
}
.label-incomplete {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 12px;
  font-weight: 700;
  color: #5a6268;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  border-radius: 0.25rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
.searchBar {
  display: flex;
  background-color: #f3f3f3;
  align-items: center;
  margin-bottom: 9px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.searchBarInput {
  border: none;
  font-size: 15px;
  outline: none;
  padding: 11px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 300px !important;
}

.searchIcon {
  margin-left: 5px;
  font-size: 30px !important;
  color: #a9a9a9 !important;
  cursor: pointer;
}

/* .email.false{
    background-color: #c94f4f !important;
    color: white !important;
} */
.block.false {
  color: red !important;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 2px 6px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.visibilityIc {
  font-size: 18px !important;
  cursor: pointer;
  color: rgb(26, 121, 128);
}

.blockIcon {
  cursor: pointer;
  font-size: 18px !important;
  font-weight: 600;
}

.userListDelete {
  color: #c52424;
  font-size: 18px !important;
  cursor: pointer;
}
.userListDelete:active {
  transform: translateY(2px);
}

.chipSeller.Pending {
  color: rgb(44, 44, 41) !important;
}

.block:hover {
  cursor: pointer;
}

.chipSeller {
  background-color: rgb(235, 220, 201) !important;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ; */
}

.chipEmail {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ; */
  background-color: #ebebd4 !important;
}

.gridBox {
  background-color: white !important;
}

.Not.uploaded {
  color: rgb(145, 141, 141) !important;
}

.Verified {
  color: #0f6e40 !important;
}

.Approved {
  color: #0f6e40 !important;
}

.Rejected,
.Not.Verified {
  color: #bb3030 !important;
}

.addUnitInput {
  width: 350px;
  padding: 10px 18px;
  margin: 8px 0;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.addUnitInput:focus {
  border: 3px solid #7e7c7c;
}

.statusChip {
  background-color: #729728 !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
.statusChipPr{
  background-color: #226cb1 !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
.FreeBreakfastSharp {
  color: white !important;
  font-size: 23px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.WorkspacePremium {
  color: white !important;
  font-size: 23px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.statusChipVerified {
  background-color: #2c9728 !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
.statusChipActive{
    background-color: #2c9728 !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    height: 26px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }

  .statusChipBlock{
    background-color: #a72a0a !important;
    color: white !important;
    border: 1px solid #cccccc !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
  }


.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
  background-color: #10121b !important;
  color: white !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
  color: white !important;
}

.topImage{
  position: relative;
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, #cc66ff 41%, #ff99cc 100%);
}
.user-image-circle{
  position: absolute;
  top: 40px;
  left: 40px;
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  line-height: 20px;
  align-items: center;
  background-color: white;
  padding: 10px;
}
.user-image-circle img{
  border-radius: 100px;
  object-fit: cover;
  width: 100px;
  height: 100px;
}

.w-100{
  width: 100%;
}

.detailIcon{
  font-size: 21px !important;
}

.mainWrapper {
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

}
.userAddButton {
  background-color: steelblue;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 20px;
  color: white;
  border: none;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.userDetCont {
  display: flex;
  min-width: 560px;
  margin: 10px;
  padding: 20px;

  border-radius: 5px;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.WorkspacePremiumDet {
  color: rgb(48, 127, 180) !important;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.businessDetCont {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px #000000;
  box-shadow: 0px 0px 5px 0px #000000;
  min-width: 500px;
  min-height: 500px;
}

.detailTitle {
  font-size: 24px;
  font-weight: 600;

}

.detailTitle2 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(181 181 181)
}

.userUpdateForm {
  flex-direction: column;
  margin-top: 10px;
}

.imgCont {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  /* width: 200px; */
  /* height: 200px; */
  /* border-radius: 10px; */
  /* display: block; */
  margin-left: auto;
  margin-right: auto;

}


.infoContainer {
  padding: 10px;

}

.infoTable {
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start; */
  display: flex;
  padding: 7px;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  margin-top: 10px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* color: #8b3939;
  background-color: rgb(255 245 245); */

}




.labelCont {
  font-weight: 600;
  min-width: 170px;
  ;
  font-size: 15px;
  /* margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;  */
  /* margin-bottom: 5px;
font-size: 14px; */
}

.userInput {
  /* margin-bottom: 5px; */
  font-size: 15px;
  margin-left: 40px;
  font-weight: 500;
  min-width: 250px;
  border-radius: 4px;
  outline: none;
  border: none;
  /* color: white; */
  /* background-color: rgb(146, 119, 84); */
  /* margin-left: 6px;
border: none;
color: black !important;
background-color: rgb(255 245 245); */
  /* width: 250px;
height: 30px; */
  border-bottom: 1px solid #dbb3b3;
}

.licenseImg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.licenseCont {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  display: block;
  object-fit: cover;
}

.arrow {
  color: rgba(0, 0, 0, 0.3);
  margin-top: 30%;
  width: 60px !important;

}


.licUpCont {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

}



.licSubmit.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.form-control {
  width: 162px;
  height: 36px;
  margin-left: 20px;
  color: black;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.form-control:hover {
  transform: translate(0, -5px);

}

.licupLabel {
  margin-top: 6px;
  color: rgb(201, 130, 130)
}

/* .imgCont2{
  width: 200px !important;
  height: 200px!important; 
  border-radius: 10px!important;
  display: block!important;
  margin-left: auto!important;
  margin-right: auto!important;  
  object-fit: cover!important;
} */


.blockBtn{
  background-color: steelblue;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 20px;
  color: white;
  border: none;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.unBlockBtn{
  background-color: rgb(180, 70, 70);
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 20px;
  color: white;
  border: none;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.deleteBtn{
  background-color: rgb(24, 131, 86);
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 20px;
  color: white;
  border: none;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.user-detailsImage{
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.imageUploadIcon{
  font-size: 40px !important;
  cursor: pointer;
  color: lightgrey;
}

.chat-page{
  position: relative;
  height: 80vh;
  width: 100%;
  padding: 30px;
  background: lightgrey;
}
.chat-page input{
  position: absolute;
  bottom: 0;
  width: 400px;
  padding-bottom: 10px;
}

.chat-page button{
  position: absolute;
  bottom: 0;
  right: 10px;
  padding-bottom: 10px;
}
.service-overflow{
  height: 200px;
  overflow: auto;
  padding: 10px;
  margin-top: 20px;
}
.service-style .heading{
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}

.service-style .name{
  font-size: 14px;
  color: #000814;
  font-weight: 600;
  text-transform: capitalize;
}


.service-style p{
  font-size: 14px;
  color: #6c757d;
  font-weight: normal;
  text-transform: capitalize;
}

.form-handler .MuiInputBase-root, .MuiTablePagination-actions{
  margin-top: 0 !important;
}

.form-handler .MuiFormControl-root{
  width: 100%;
}

.datepicker {
  padding: 14px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.1);
  margin-top : 10px
}
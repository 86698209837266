.sidebar {
  background-color: #fff !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  background-color: #fff !important;
}
.sidebarWrapper {
  padding: 10px;
  margin-top: 40px;
  background-color: #fff !important;
}

.sidebarTitle {
  font-size: 14px;
  color: #000;
  display: none;
  font-family: monospace;
  font-weight: 600;
  text-transform: uppercase;
}
.sidebarList {
  list-style: none;
  padding: 5px;
}
.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  border-radius: 19px 0px;
  color: black;
}
li.sidebarListItem:hover {
  margin-top: 1%;
  background-color: #a88a70;
  color: white !important;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

a.link.active .sidebarListItem {
  background-color: #a88a70;
  padding-left: 12px;
  margin-top: 3px;
  color: #37BA9A !important;
  margin-bottom: 3px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sidebarIcon {
  margin-right: 15px;
  font-size: 20px !important;
}

.sidebarSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sidebarSelectHead {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}
.sidebarSelectList {
  padding-left: 9px;
  margin-top: 10px;
}
.sidebarSelectList div {
  display: flex;
  padding: 8px 8px 10px 8px;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  align-items: flex-start;
  color: #000;
  list-style: none;
  font-size: 13px;
  border-left: 1px solid #ced4da;
}
.sidebarSelectList div:hover {
  text-decoration-line: underline;
}

.sidebarSelectList .active div {
  color: #37BA9A;
  border-left: 1px solid #37BA9A;
  text-decoration-line: underline;
}

.arrowIcon {
  font-size: 22px !important;
  color: #6c757d;
  transition: transform 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
}

.sidebarSelectHead .icon {
  font-size: 22px !important;
  color: #6c757d;
}

.sidebarSelectHead span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.50);
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.active .sidebarSelectHead span {
  color: #37BA9A;
  text-decoration-line: underline;
}

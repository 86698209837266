.cardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cardContainer {
  display: flex;
  min-width: 240px;
  margin: 10px;
  padding: 20px;
  cursor: pointer;
  border-radius: 4px;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out; /* Adjust the transition duration as needed */
}

.cardContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  transform: translateY(-3px); /* Use translateY instead of translate */
}

.cardContainer:hover {
  transform: translate(0, -5px);
  /* background-color: #c16565;
    -webkit-box-shadow: 0px 0px 5px 0px #c16565; */
  /* box-shadow: 0px 0px 5px 0px #c16565; */
}

.cardTitle {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: right;
}

.cardTopLogo {
  margin-bottom: 10px;
  text-align: right;
}

.cardLogo {
  align-items: flex-end;
  color: #000000 !important;
  font-size: 60px !important;
}

.userCount {
  margin-top: 4px;
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  align-items: center;
}
.trendingLogo {
  margin-right: 6px;
  font-weight: 500 !important;
  font-size: 23px !important;
  color: #000000;
}

@keyframes glowing {
    0% { text-shadow: 0 0 10px #000000; }
    50% { text-shadow: 0 0 40px #000000; }
    100% { text-shadow: 0 0 10px #000000; }
}

.glowText{
    font-size:16px;
    color: #000000;
    animation: glowing 2s infinite;
}